import React, { useEffect, useRef, useState } from 'react'
// import { Swiper, SwiperSlide } from "swiper/react";
import styles from './testmonial.module.css'
import Section from '../section/Section'
import { Fade } from 'react-reveal'

import "./test.css"

// import "./testmonial.css"
const TESTIMONIAL_DELAY = 5000
const TestimonialSlider = (props) => {
  
    const { testimonialData } = props
    const refFeedbackParentDiv = useRef(null)
    const refButtonsParentDiv = useRef(null)
    const timeoutRef = useRef(null)
    const [delay, setDelay] = useState(100)
    const [index, setIndex] = useState(0)

    useEffect(() => setDelay(TESTIMONIAL_DELAY), [])

    useEffect(() => {
      timeoutRef.current = setTimeout(
        () =>
          setIndex((prevIndex) =>
            prevIndex === props.testimonialData.length - 1 ? 0 : prevIndex + 1
          ),
        delay
      )

      return () => clearTimeout(timeoutRef.current)
    }, [props.testimonialData.length, index, delay])

    const dotsHelper = (idx) => {
      if (refButtonsParentDiv.current === null) return

      if (index === idx) {
        const arr2 = [...refFeedbackParentDiv.current.children]

        arr2.forEach((el, i) => {
          if (document.querySelector(`.feedbackText--${i}`))
            document
              .querySelector(`.feedbackText--${i}`)
              .classList.add(styles['not-visible'])
        })

        arr2[index].classList.remove(styles['not-visible'])

        return styles['myDot--active']
      }
    }

    const dotClickHandler = (arr, indx) => {
      setIndex(indx)
      arr.forEach((_, i) => {
        document
          .querySelector(`.feedbackText--${i}`)
          .classList.add(styles['not-visible'])
        document
          .querySelector(`.buttonDot${i}`)
          .classList.remove(styles['myDot--active'])
      })
      document
        .querySelector(`.feedbackText--${indx}`)
        .classList.remove(styles['not-visible'])
      document
        .querySelector(`.buttonDot${indx}`)
        .classList.add(styles['myDot--active'])
    }

    return (
      <div className='test-bg'>
       
      <Section title="Testimonials">
      <Fade>
        <div className={`${styles['section-three-main-div']} testimonial`}>
        
          <div
            ref={refFeedbackParentDiv}
            className={styles['section-three-sub-div-one']}
          >
            {/* <div
            className={`${styles["quotes-img"]} ${styles["quotes-img-right"]}`}
          >
            <img src="./left.png"></img>
            </div> */}
            {testimonialData.map((el, i) => {
              return (
                <div
                  key={i}
                  className={`feedbackText--${i} ${styles['main-quotes-div']} ${styles['not-visible']}`}
                >
                  <div className={styles.para}>"{el.testimonial}"</div>
                  <img alt="m.jigalin profile" src={el.logo} className='test-img'/>
                  <div className={styles.subText}>{el.author}</div>
                  <div className='test-desigination'>{el.desigination}</div>
                </div>
              )
            })}
            <div
              className={`${styles['quotes-img']} ${styles['quotes-img-left']}`}
            />
          </div>
          <div ref={refButtonsParentDiv}>
            {testimonialData.map((_, i, arr) => {
              return (
                <div
                  name="change testimonial"
                  key={i}
                  className={`buttonDot${i} ${styles.myDot} ${
                    index === i ? dotsHelper(i) : ''
                  }`}
                  onClick={() => dotClickHandler(arr, i)}
                />
              )
            })}
          </div>
        </div>
        </Fade> 
      </Section>
      
      </div>
    )
  
}

export default React.memo(TestimonialSlider)
