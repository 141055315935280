  import React, { useState } from 'react';
  import 'bootstrap/dist/css/bootstrap.css';
  import './ContactForm.css';
  import { FaLocationDot } from "react-icons/fa6";
  import { IoIosMail } from "react-icons/io";
  import { FaWhatsapp } from "react-icons/fa";
  import { Bounce, Fade } from 'react-reveal';

  function ContactForm() {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleSubmit = async (event) => {
      event.preventDefault();
      const form = event.target;
      const formData = new FormData(form);
      
      try {
        const response = await fetch('https://formspree.io/f/myyrvnyq', {
          method: 'POST',
          body: formData,
          headers: {
            'Accept': 'application/json'
          }
        });
        
        if (response.ok) {
          console.log("Form submitted successfully");
          setFormSubmitted(true);
        } else {
          console.error('Form submission failed:', response.statusText);
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
      }
    };
    return (
      <>
        <section id="contact" className="contact">
          <Fade>
            <div className="container" data-aos="fade-up">
              <div className="row mt-1">
                <div className="col-lg-4">
                  <div className="info">
                    <div className="address">
                      <Bounce><FaLocationDot className="i"/></Bounce>      
                      <h4>Location:</h4>
                      <p>
                        <a href="https://www.google.com/maps/place/Lahore,+Punjab,+Pakistan/@31.482518,74.0047223,10z/data=!3m1!4b1!4m6!3m5!1s0x39190483e58107d9:0xc23abe6ccc7e2462!8m2!3d31.5203696!4d74.3587473!16zL20vMHhudDU?entry=ttu"
                          style={{textDecoration: 'none', listStyle: 'none', color: 'black', fontWeight:"400"}}
                          target="_blank"
                          rel="noopener noreferrer">
                          Lahore, Pakistan.
                        </a>
                      </p>
                    </div>
                    <div className="email">
                      <Bounce><IoIosMail className='i'/></Bounce>
                      <h4>Email:</h4>
                      <p>
                        <a href="mailto:ch.hammad.ahmad183@gmail.com"
                          style={{textDecoration: 'none', listStyle: 'none', color: 'black',fontWeight:"400"}}
                          target="_blank"
                          rel="noopener noreferrer">
                          ch.hammad.ahmad183@gmail.com
                        </a>
                      </p>
                    </div>
                    <div className="phone">
                      <Bounce><FaWhatsapp className='i'/></Bounce>
                      <h4>Call:</h4>
                      <p>
                        <a href="tel:+923327217637"
                          style={{textDecoration: 'none', listStyle: 'none', color: 'black',fontWeight:"400"}}
                          target="_blank"
                          rel="noopener noreferrer">
                          +923327217637
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 mt-5 mt-lg-0">
                  {!formSubmitted ? (
                    <form onSubmit={handleSubmit} className="email-form">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input type="text" name="name" className="form-control" id="name" placeholder="Enter Full Name" required />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <input type="email" className="form-control" name="email" id="email" placeholder="Enter Your Email" required />
                        </div>
                      </div>
                      <div className="form-group mt-3">
                        <input type="tel" className="form-control" name="number" id="number" placeholder="Enter Phone number" required />
                      </div>
                      <div className="form-group mt-3">
                        <textarea className="form-control" id="message" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                      </div>
                      <div className="text-center">
                        <Bounce>
                          <button id="contact_btn" type="submit">Send Message</button>
                        </Bounce>
                      </div>
                    </form>
                  ) : (
                    <div className="thank-you-message">
                      <h2>Thank you for your message!</h2>
                      <p>We'll get back to you as soon as possible.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Fade>
        </section>
      </>
    );
  }

  export default ContactForm;
