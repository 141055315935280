import React from 'react'
import './App.css'
import Home from './components/home/Home'
import About from './components/about/About'
import Do from "./components/do/do"
import Experience from './components/experience/Experience'
import Skills from "./components/skill/skill"
import Projects from './components/projects/Projects'
import Faq from './components/faq/faq'
import Testimonial from './components/testimonial/testmonial'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import TopButton from './components/topButton/TopButton'
import data from "./data.json";


function App() {
  return (
    <div className="App">
      <Home />
      <About />
      <Do/>
      <Experience />
      <Skills />
      <Projects/>
      <Faq/>
      <Testimonial testimonialData={data}/>
      <Contact />
      <Footer />
      <TopButton />
    </div>
  )
}

export default App
