import React from 'react'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import { FaCode } from 'react-icons/fa'
import { GrGroup } from "react-icons/gr";
import { SiAmazonsimpleemailservice } from 'react-icons/si'
import { MdOutlineHeadsetMic } from 'react-icons/md'


import './do.css'

const Do = () => {
  return (
    <Section title="Services" id="whatido">
      <Fade className="main">
        <h3 className="head">How I can help your next project</h3>
        <div className="container">
          <div className="row hero1">
            <div className="col-lg-4 data col-12">
              <div className="icon">
                <FaCode />
              </div>
              <h3>Web Development</h3>
              <p>I help my clients define actionable roadmaps for building their product. I am known for creating the most beautiful and intuitive user interfaces across multiple platforms.</p>
            </div>
            <div className="col-lg-4 data col-12">
              <div className="icon">
                <GrGroup />
              </div>
              <h3>Virtual Assistant</h3>
              <p>Streamlining tasks, enhancing productivity, and ensuring seamless operations through tailored virtual solutions for optimized efficiency.</p>
            </div>
            {/* <div className="col-lg-6 data">
              <div className="icon">
                <SiAmazonsimpleemailservice />
              </div>
              <h3>Product Planning & Design</h3>
              <p>I help my clients define actionable roadmaps for building their product. I am known for creating the most beautiful and intuitive user interfaces across multiple platforms.</p>
            </div>
            <div className="col-lg-6 data">
              <div className="icon">
                <MdOutlineHeadsetMic />
              </div>
              <h3>Product Planning & Design</h3>
              <p>I help my clients define actionable roadmaps for building their product. I am known for creating the most beautiful and intuitive user interfaces across multiple platforms.</p>
            </div> */}
          </div>
        </div>
        <div className='container'>
            <div className='row hero2'>
                   <div className="col-lg-4 data col-12">
              <div className="icon">
                <SiAmazonsimpleemailservice />
              </div>
              <h3>Email Marketing</h3>
              <p>I leverage strategic email campaigns to engage audiences and drive conversions effectively, utilizing advanced analytics to track performance and tailor content for maximum impact.</p>
            </div>
            <div className="col-lg-4 data col-12">
              <div className="icon">
                <MdOutlineHeadsetMic />
              </div>
              <h3>Customer Services</h3>
              <p>Delivering personalized customer experiences and resolving inquiries promptly, I prioritize satisfaction at every touchpoint. Through active listening and innovative solutions, I consistently exceed expectations to foster lasting loyalty.</p>
            </div>
            </div>
        </div>
      </Fade>
    </Section>
  )
}

export default Do
