import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import './Projects.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'

const Projects = () => {
  const [show, setShow] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)

  const handleClose = () => setShow(false)
  const handleShow = (project) => {
    setSelectedProject(project)
    setShow(true)
  }
  const projectsData = [
    {
      id: 1,
      name: 'Lively',
      image: 'lively-square.png',
      url:'www.wearlively.com',
      url_link:"https://www.wearlively.com",
      technologies: "ROR, React",
      industry:"IT",
      description: 'One of the world’s biggest online store of women products. RoR based stack, development and enhancements to existing code-base. we developed custom web app for automation of their ambassadors program to increase sales . which help to quickly get commision based sales and track them according. Custom Web portal for managing their Vendors and inventory , so that they have enough inventory topped up always.Fetching their Shopify orders and custom reporting on sales & products.'
    },
    {
      id: 2,
      name: 'Zentap',
      image: 'zentap-square.png',
      url:'www.zentap.com',
      url_link:"https://www.zentap.com/",
      technologies: "ROR, React",
      industry:"IT",
      description: 'Zentap enables real estate agents to thrive with software that streamlines their real estate marketing to help gain more leads and close more business.'
    },
    {
      id: 3,
      name: 'Profocio',
      image: 'profocio-square.png',
      url:'www.proficio.com',
      url_link:"https://www.proficio.com/",
      technologies: "iOS, HTML5, CSS3, PHP, Java",
      industry:"IT",
      description: 'Working directly with their CTO, we’re helping them enhance and add in new features to their website. Developed in Node, Angular and Mongo DB.'
    },
    {
      id: 4,
      name: 'Havoc Parts',
      image: 'havoc.png',
      url:'www.havoc-parts.com',
      url_link:"https://www.havoc-parts.com/",
      technologies:"Shopify",
      industry:"IT",
      description: "At Havoc Parts, our commitment to seamless e-commerce solutions is exemplified through our partnership. We've enhanced their online presence by meticulously uploading products and crafting custom product pages. Our collaboration resulted in visually striking and user-friendly pages, maximizing engagement and conversions. Additionally, our research insights and eBay expertise have empowered Havoc Parts to stay ahead in a competitive market. We continue to drive sustained e-commerce success for Havoc Parts as their trusted ally."
    },
    {
      id: 5,
      name: 'Dr.Ganja',
      image: 'Ganja.png',
      url:'www.drganja.com',
      url_link:"https://www.drganja.com/",
      technologies:"Wordpress",
      industry:"IT",
      description: "At Dr. Ganja, we're dedicated to seamless order processing and meticulous product catalog management. Our team ensures efficient transactions and accurate inventory representation, leveraging advanced technology. We prioritize transparency, integrating backend operations for product credibility and compliance. Additionally, we offer prompt tracking ID delivery for a seamless post-purchase experience. As a reliable partner, we combine technology and expertise for mutual success."
    },
    {
      id: 6,
      name: 'Merley',
      image: 'Marley.png',
      url:'www.merley.com',
      url_link:"https://www.merley.se/",
      technologies:"Shopify",
      industry:"IT",
      description: "We're dedicated to enhancing the online retail experience for businesses like Merley. Our services include proficient product uploads on Shopify, ensuring accuracy and user-friendliness. We excel in managing client queries, fostering positive customer interactions, and building strong relationships. Our partnership with Merley focuses on growth and success. With our expertise in Shopify management and client communication, we empower Merley to thrive in the competitive e-commerce landscape"
    },
    // Add more project objects as needed
  ];

  return (
    <div className="bg">
      <Section title="Projects">
        <Fade>
          <div className="container pr-cont">
            <div className="row g-3">
            {projectsData.map(project => (
                <div key={project.id} className="col-lg-4 main col-sm-6" onClick={() => handleShow(project)}>
                  <img src={project.image} className=" main-img" alt={project.name}></img>
                  <div className="overlay">
                    <div className="text-12">{project.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <Modal show={show} onHide={handleClose} className="modal">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
            {selectedProject && (
              <>
              <Modal.Title className="text-center mb-4">{selectedProject.name}</Modal.Title>

              <div className="container">
                <div className="row">
                  <div className="col-lg-7 project-img-cont">
                    <img src={selectedProject.image} className="img-project" alt="project_img"></img>
                  </div>
                  <div className="col-lg-5">
                    <h4 className="text-1">Project Info:</h4>
                    <p>
                    {selectedProject.description}
                    </p>

                    <h4 className="text-1">Project Details:</h4>
                    <ul className="list">
                      <li>
                        <span className="items">Client:</span>{selectedProject.name}
                      </li>
                      <li>
                        <span className="items">Technologies:</span>{selectedProject.technologies}
                      </li>
                      <li>
                        <span className="items">Industry:</span>{selectedProject.industry}
                      </li>
                      <li>
                        <span className="items ">URL:</span>
                        <a
                          href={selectedProject.url_link}
                          class="btn btn-primary shadow-none rounded-0 px-3 py-1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedProject.url}
                          <i class="fas fa-external-link-alt ms-1"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              </>
            )}
            </Modal.Body>
          </Modal>
        </Fade>
      </Section>
    </div>
  )
}

export default Projects
