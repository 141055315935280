import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import './faq.css'
import Section from '../section/Section'
import { IoIosArrowDown } from "react-icons/io";
import { Fade } from 'react-reveal'


const Faq = () => {
  return (
    <Section title="Faq" className="faq-area pt-100 pb-70">
      <Fade>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="faq-accordion">
                <Accordion>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                     <IoIosArrowDown className="IoIosArrowDown"/> <span className="text">What tasks can a virtual assistant handle for me?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    
                    <AccordionItemPanel>
                      <p>
                      Virtual assistants can manage administrative tasks, handle emails, schedule appointments, conduct research, and provide general support, freeing up your time for more strategic activities.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      <IoIosArrowDown className="IoIosArrowDown"/> <span className="text">Can I customize the services of a virtual assistant based on my specific business needs?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    

                    <AccordionItemPanel>
                    <p>
                    Absolutely. Our virtual assistant services are highly customizable. You can tailor the tasks and responsibilities based on your unique requirements, ensuring a personalized and efficient support system.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      <IoIosArrowDown className="IoIosArrowDown"/> <span className="text">How do you ensure the security of sensitive data when using virtual assistant services?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    

                    <AccordionItemPanel>
                      <p>
                      We prioritize data security through encrypted communication channels, secure data storage, and strict confidentiality measures. Our virtual assistants are trained to handle sensitive information with the utmost care and adhere to industry-standard security protocols.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      <IoIosArrowDown className="IoIosArrowDown"/>  <span className="text">How does web development contribute to business growth?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    

                    <AccordionItemPanel>
                      <p>
                      Web development enhances online presence, improves user experience, and ensures a responsive and visually appealing website. This, in turn, attracts more visitors, boosts engagement, and contributes to increased conversions.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      <IoIosArrowDown className="IoIosArrowDown"/><span className="text"> What technologies do you use for web development, and how can they benefit my project?</span>
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p>
                      We harness the power of leading-edge technologies, including React, Angular, Vue.js, Ruby on Rails (RoR), Node.js, MongoDB, SaaS architecture, Tailwind CSS, GitHub, Azure, and other innovative frameworks. This strategic stack empowers us to craft websites that are not only robust and scalable but also optimized for exceptional performance, fortified security, and seamless adaptability. Our commitment is to deliver a digital experience that aligns precisely with the unique requirements of your project, ensuring it stands out in today's dynamic online landscape.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="faq-img">
                <img src="/faq1.png" alt="idea" />
              </div>
            </div>
          </div>
        </div>
        </Fade>
    </Section>
  );
};

export default Faq;
