import React from 'react'
import './About.css'
import { Fade } from 'react-reveal'
import Section from '../section/Section'

const About = () => {
  return (
    <Section title="About">
      {/* <Fade duration={1000}>
        <div className='parent'>
          <div className='info-wrapper'>
            <h5>Name:</h5>
            <p>Farooq Ashraf</p>
          </div>

          <div className='info-wrapper'>
            <h5>Email:</h5>
            <p>farooqch11@gmail.com</p>
          </div>

          <div className='info-wrapper'>
            <h5>Date of birth:</h5>
            <p>15 October, 1992</p>
          </div>

          <div className='info-wrapper'>
            <h5>From:</h5>
            <p>Lahore, Pakistan.</p>
        </div>
        </div>
      </Fade> */}
      <Fade>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="about-text">
                <h2>Who am I?</h2>
                <p className="main-name">
                  Hi, I'm <span className="name-underline">Hammad Ahmad</span>
                  {/* <span role="img" aria-label="lightning">
                😄
              </span>{' '} */}
                </p>
                <p>
                I'm a passionate web design consultant and virtual assistant, dedicated to delivering exceptional results within time and budget. Specializing in crafting simple, clean, and sleek websites, I've helped thousands of clients achieve their goals. As your virtual assistant, I streamline processes and ensure smooth project management from start to finish. Let's collaborate to exceed expectations together.
                </p>
                <p></p>
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="about-text-right">
                <div className="inner-text1">
                  {' '}
                  <span className="eight">8</span>
                  <p className="inner-text2">
                    Years of <span className="exp">Experiance</span>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade>
        <div className="container ">
          <div className="row info-container">
            <div className="col-lg-3 col-6 info-wrapper">
              <p className="hed-info">Name:</p>
              <p className="info">Hammad Ahmad</p>
            </div>
            <div className="col-lg-3 col-6 info-wrapper">
              <p className="hed-info">Email:</p>
              <p className="info">
                <a href="mailto:ch.hammad.ahmad183@gmail.com" style={{textDecoration: 'none', listStyle: 'none', color: 'black'}}>
                ch.hammad.ahmad183@gmail.com
                </a></p>
            </div>
            <div className="col-lg-3 col-6 info-wrapper">
              <p className="hed-info">Date of birth:</p>
              <p className="info">30 March, 1994</p>
            </div>
            <div className="col-lg-3 col-6 info-wrapper">
              <p className="hed-info">From:</p>
              <p className="info">Lahore, Pakistan.</p>
            </div>
          </div>
        </div>
      </Fade>
    </Section>
  )
}

export default About
