import React from 'react'
import './Contact.css'
import Section from '../section/Section'
import ContactForm from '../contactForm/ContactForm'


const Contact = () => {
  return (
    <Section title="Contact">
      <ContactForm />
      {/* <Bounce cascade>
        <div className="links">
          <a
            href="https://github.com/jigalin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="Github Logo" width="40px" />
          </a>
          <a
            href="https://www.linkedin.com/in/mjigalin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="Linkedin Logo" width="40px" />
          </a>

          <a
            href="https://www.facebook.com/matthew.jigalin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} alt="Facebook Logo" width="40px" />
          </a>
        </div>
      </Bounce> */}
    </Section>
  )
}

export default Contact
