import React from 'react'
import { Fade } from 'react-reveal'
import Section from '../section/Section'
import './skill.css'

const skill = () => {
  return (
    <Section title="Skills">
        <Fade>
        <div className="container" data-aos="fade-up">
          <div className="row skills-content">
            <div className="col-lg-6">
              <div className="progress">
                <span className="skill">
                React Js <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                Vue Js <i className="val">95%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={95}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '95%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                Angular Js <i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '80%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                Ruby on Rails <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                  Node JS <i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '80%' }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="progress">
                <span className="skill">
                Administrative Support <i className="val">80%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '80%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                Communication Skills <i className="val">100%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={50}
                    aria-valuemin={0}
                    style={{ width: '100%' }}
                    aria-valuemax={100}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                Time Management<i className="val">90%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={90}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '90%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                  {' '}
                  Customer Service<i className="val">85%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{ width: '85%' }}
                  />
                </div>
              </div>
              <div className="progress">
                <span className="skill">
                  {' '}
                  Problem Solving<i className="val">98%</i>
                </span>
                <div className="progress-bar-wrap">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '98%' }}
                    aria-valuenow={98}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Section>
  )
}
export default skill
