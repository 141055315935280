import React, { useState } from 'react'
import './Home.css'
import Fade from 'react-reveal/Fade'
import { Link } from 'react-scroll'
import Particles from 'react-particles-js'
import Typewriter from 'typewriter-effect'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Navbar from '../navbar/Navbar'
import config from '../../config'
import profile from '../../images/matt.png'

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false)
  return (
    <div className="home-wrapper">
      <div className="home">
        <Particles className="particles" params={config.particles} />
        <div className={`greeting${!imageLoaded ? ' hide' : ''}`}>
          <Fade bottom distance="40px">
            <img
              className="profile"
              alt="Hammad profile"
              src={profile}
              onLoad={() => setImageLoaded(true)}
            />
            <p className="greeting-text">
             <span className="name"> Digital Nomad</span>{' '}
              <span className="wave-emoji" role="img" aria-label="waving hand">
                 👽
              </span>
            </p>
            <div className="greeting-text2">
              <Typewriter
                options={{
                  strings: [
                    'I craft intuitive website interfaces with joy',
                    "Exploring emerging web technologies excites me",
                    'Networking with developers and clients energizes me',
                    'I specialize in innovative virtual assistant solutions',
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 10,
                  cursor: '....',
                  delay: 50,
                }}
              />
            </div>
            {/* <Bounce cascade>
              <div className="links">
                <a
                  href="https://www.linkedin.com/in/mjigalin/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/linkedin_black.png" alt="Linkedin Logo" width="50px" />
                </a>

              </div>
            </Bounce> */}
            <div className="scroll-down">
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-63}
                duration={500}
              >
                <ArrowDropDownCircleIcon
                  fontSize="large"
                  style={{ pointerEvents: 'fill', cursor: 'pointer', color:"rgb(250, 150, 0)" }}
                />
              </Link>
            </div>
          </Fade>
        </div>
        <Navbar className="mt-10"/>

      </div>

    </div>
  )
}

export default Home
